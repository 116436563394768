/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { findKey } from 'lodash'
import SEO from '../components/seo'
import Layout from '../components/Layout'

import '../components/pricing.css'

export default ({ data, location }) => {
  const { frontmatter } = data.GetPricingPage
  return (
    <div>
      <SEO title="Cennik" pathname={location.pathname} keywords="cennik, oferta, szkoła językowa, Bielsko-Biała, grecki, język grecki" />
      <Layout>
        <div className="pricing">
          <h1>{frontmatter.title}</h1>
          <ul className="pricing__offers outer">
            {data.GetOfferPage.edges.map((edge) => {
              if (findKey(data.GetPricingItems.edges,
                o => o.node.frontmatter.offer === edge.node.frontmatter.name) != null) {
                return (
                  <li key={edge.node.id}>
                    <a href={edge.node.fields.slug}>
                      <Img className="pricing__offers--thumbnail" fluid={edge.node.frontmatter.picture.childImageSharp.fluid} alt={edge.node.id} />
                    </a>
                    <div className="pricing__offers--right">
                      <h3 className="pricing__offers--title">
                        <a href={edge.node.fields.slug}>{edge.node.frontmatter.name}:</a>
                      </h3>
                      <ul key={edge.node.id} className="pricing__offers inner">
                        {data.GetPricingItems.edges.map((schedule) => {
                          if (schedule.node.frontmatter.offer === edge.node.frontmatter.name) {
                            return (
                              <li key={schedule.node.id}>
                                <p>{schedule.node.frontmatter.description}</p>
                                <p>Cena: {schedule.node.frontmatter.price}/{schedule.node.frontmatter.quantity}</p>
                              </li>
                            )
                          }
                        })}
                      </ul>
                    </div>
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    GetPricingPage: markdownRemark( frontmatter: { templateKey: { eq: "pricing-page" } } ) {
      frontmatter {
        title
      }
    }
    GetOfferPage: allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___position]}, filter: { frontmatter: { templateKey: { eq: "offer-page" } } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            name
            position
            picture {
              childImageSharp {
                fluid (maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    GetPricingItems: allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___position]}, filter: { frontmatter: { templateKey: { eq: "pricing-item" } } }) {
      edges {
        node {
          id
          frontmatter {
            offer
            quantity
            price
            description
            position
          }
        }
      }
    }
  }
  `
